<template>
  <div id="trending" class="trending mt-4 mr-0">
    <h4 class="my-2">
      <b-icon-graph-up variant="danger" font-scale="1"> </b-icon-graph-up>
      Trending <span style="color: #de3e4e">Now</span>
    </h4>
    <hr class="my-1" />
    <div class="accordion" role="tablist">
      <b-card no-body class="mb-1">
        <b-card-header header-tag="header" class="p-1" role="tab">
          <b-button block v-b-toggle.accordion-1 variant="info"
            >Top 10 in 24 hours</b-button
          >
        </b-card-header>
        <b-collapse id="accordion-1" accordion="my-accordion" role="tabpanel">
          <TrendingPostBody :trending="trendingPosts.daily"></TrendingPostBody>
        </b-collapse>
      </b-card>

      <b-card no-body class="mb-1">
        <b-card-header header-tag="header" class="p-1" role="tab">
          <b-button block v-b-toggle.accordion-2 variant="info"
            >Weekly Top 10</b-button
          >
        </b-card-header>
        <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
          <TrendingPostBody :trending="trendingPosts.weekly"></TrendingPostBody>
        </b-collapse>
      </b-card>

      <b-card no-body class="mb-1">
        <b-card-header header-tag="header" class="p-1" role="tab">
          <b-button block v-b-toggle.accordion-3 variant="info"
            >Top 10 of {{ currentMonth }}</b-button
          >
        </b-card-header>
        <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
          <TrendingPostBody
            :trending="trendingPosts.monthly"
          ></TrendingPostBody>
        </b-collapse>
      </b-card>
      <b-card no-body class="mb-1">
        <b-card-header header-tag="header" class="p-1" role="tab">
          <b-button block v-b-toggle.accordion-4 variant="info"
            >Best 10 of All time</b-button
          >
        </b-card-header>
        <b-collapse
          visible
          id="accordion-4"
          accordion="my-accordion"
          role="tabpanel"
        >
          <TrendingPostBody
            :trending="trendingPosts.allTime"
          ></TrendingPostBody>
        </b-collapse>
      </b-card>
    </div>
  </div>
</template>

<script>
import moment from "moment/src/moment";
import { mapActions, mapGetters } from "vuex";
import {
  BCard,
  BCardHeader,
  BButton,
  BCollapse,
  BIconGraphUp,
} from "bootstrap-vue";

export default {
  components: {
    TrendingPostBody: () =>
      import(/* webpackPrefetch: true */ "./TrendingPostBody.vue"),
    BCard,
    BCardHeader,
    BButton,
    BCollapse,
    BIconGraphUp,
  },

  data() {
    return {
      trending: {
        daily: [],
        weekly: [],
        monthly: [],
        allTime: [],
      },
    };
  },

  computed: {
    ...mapGetters(["trendingPosts"]),

    currentMonth: function () {
      return moment().format("MMMM, YYYY");
    },
  },

  async mounted() {
    if (!this.trendingPosts.isDataFetched) {
      try {
        await this.getTredingPosts();
      } catch (e) {
        if (e.response.status === 401) {
          this.signOut();
        }
      }
    }
  },

  methods: {
    ...mapActions(["getTredingPosts", "signOut"]),
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/trendingPost.scss";
</style>
